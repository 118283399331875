import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import Reach from gatsby
// import { Router } from "@reach/router";

//Components
import Main from '../components/Emprendimientos/main'
import About from '../components/Emprendimientos/about'
import Steps from '../components/Emprendimientos/steps'
import Investors from '../components/investors'

const IndexPage = () => (
  <Layout>
    <Seo title="Emprendimientos" />
    <Main />
    <About />
    <Investors />
    <Steps />
  </Layout>
)

export default IndexPage
