import React,{useEffect, useState} from 'react'
import Development from '../development-card'
import Loading from '../Loading'

import { connect } from 'react-redux'
import {getDevelopmentsFilterAction, updateFiltersDevelopment} from '../../redux/developmentsDucks'
import { getReverseOrder } from '../../helpers/helper.developments'
import { useGetDevelopmentsQuery } from '../../redux/middlewareTokkoApi/developments'
import { graphql, useStaticQuery } from 'gatsby'

const Developments = ({developmentsFilters,loading,api_key,dispatch}) => {

    const [filter,setFilter] = useState(0)
    const [buttons,setButtos] = useState([
        {
            name:"VER TODOS",
            value:0
        },
        {
            name:"EN POZO",
            value:3
        },
        {
            name:"EN CONSTRUCCIÓN",
            value:4
        },
        {
            name:"TERMINADOS",
            value:6
        },

    ])

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            keys{
                tokko
            }
          }
    }`)

    const { data: allDevelopmentsData, isLoading: isLoadingDevelopments } = useGetDevelopmentsQuery(realEstate.keys.tokko);

    const checkTag = (developments) => { //Function to check if this page has a tag
        const development_array = filter === 0 ? developments : developments.filter(dev => dev.construction_status === filter);
        return development_array.filter(element => !element.custom_tags.find(tag => tag.name.toLowerCase() === 'portada'))
    }


    return  (
        <section id="featured-devs" class="pt-lg-5">
                    <div className="container-fluid my-5 pb-lg-3">
                        <div className="d-flex flex-wrap justify-content-center">
                            {buttons.map((button,index) => (
                                <button className={'button-filter mb-2 mb-lg-0 ' + (filter === button.value ? ' active ' : '')} onClick={() => setFilter(button.value)}>{button.name}</button>
                            ))}
                        </div>
                    </div>
                    {
                        <div class="container-fluid">
                            <div class="row no-gutters mt-5 mt-lg-1" >
                            {allDevelopmentsData && !isLoadingDevelopments 
                                ?   getReverseOrder(checkTag(allDevelopmentsData)).map((development,index) => (
                                        <div class="col-lg-4 mb-3 mb-lg-4 pb-lg-2 px-lg-3">
                                            <Development key={index} development={development} />
                                        </div>                  
                                    ))
                                :  [1,2,3,4,5,6].map((index) => (
                                    <div class="col-lg-4 mb-3 mb-lg-4 pb-lg-2 px-lg-3">
                                        <Development skeleton={true} key={index} />
                                    </div>  
                                ))}
                            </div>
                        </div>
                    }
            
        </section> 
    )
}
export default connect(state => ({
    developmentsFilters: state.developments.developmentsFilters,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko

}),null)(Developments);