import React from 'react' 

// Components
import BannerTop from '../Global/Modules/MediaPush/BannerTop';

export default function Main() {
  return (
    <BannerTop 
        identifier="development-main"
        page="Emprendimientos"/>
  )
}