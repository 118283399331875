import React, {useEffect} from "react";
import Seo from "../components/seo"
import Layout from "../components/layout"

import About from '../components/Emprendimiento/about'
import Units from '../components/Emprendimiento/units'

import { connect } from "react-redux"

// import {getDevelopmentAction, getUnitsDevelopmentAction} from '../redux/developmentsDucks'

// Get Development data by ID
import { useParams } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { useGetDevelopmentDetailQuery } from "../redux/middlewareTokkoApi/developments";

const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Emprendimiento/main")
)

const Development = ({loading,loading_unities,api_key,dispatch}) => {

  const {id} = useParams()

  // useEffect(() => {
  //   if(api_key){
  //     dispatch(getDevelopmentAction(id))
  //     dispatch(getUnitsDevelopmentAction(id))
  //   }
  // },[api_key])

  const isSSR = typeof window === "undefined"
  
  const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              keys{
                  tokko
              }
          }
      }`
  )
  const { data: developmentData } = useGetDevelopmentDetailQuery({"API_KEY": realEstate.keys.tokko, "DEVELOPMENT_ID": id});

	return (
        <Layout preloader={true} development={developmentData}>
            <Seo title="Emprendimiento" />
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy id={id} />
              </React.Suspense>
            )}
            <About id={id} />
            <Units id={id} />
        </Layout> 
    )
}
export default connect(state => ({
    loading: state.developments.loading,
    loading_unities: state.developments.loading_unities,
    api_key: state.settings.keys.tokko
}),null)(Development);