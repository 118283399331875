import React,{useRef} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import SETTINGS from "../../images/svg/settings-light.svg";
import BUILDING from "../../images/svg/building-light.svg";
import LOCATION from "../../images/svg/location-light.svg";
import RELOJ from "../../images/svg/reloj-light.svg";
import STAR from "../../images/svg/star-light.svg";
import Map from '../map'
import OwlCarousel from 'react-owl-carousel3';
import { connect } from 'react-redux'
import Breadcrumbs from "../breadcrumbs";
// import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';

//Helpers
import {getCountUnities,getMinAndMaxPrice} from '../../helpers/helper.unities'
import { getLocation,getStatus, getContructionDate, getName, getTitle, getFakeAddress } from '../../helpers/helper.developments';
import {getPhotos,getDescription } from '../../helpers/helper.rendering'
import {getTour,getLastVideo} from '../../helpers/helper.properties'

import Loading from '../../components/Loading'
import { useGetDevelopmentDetailQuery, useGetUnitiesQuery } from '../../redux/middlewareTokkoApi/developments';
import Files from '../files';


const About = ({id,dispatch}) => {
    const slider = useRef()

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            keys{
                tokko
            }
        }
    }`)

    const options = {
        settings: {
            overlayColor: "rgba(0, 0, 0, 0.9)",
            autoplaySpeed: 1500,
            transitionSpeed: 900,
            disableWheelControls:true
        },
        buttons: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            iconColor: "rgba(255,255,255, 1)",
        },
        caption: {
            captionColor: "#000",
            captionFontFamily: "Raleway, sans-serif",
            captionFontWeight: "300",
            captionTextTransform: "uppercase",
        }
    };

    const { data: development, isLoading } = useGetDevelopmentDetailQuery({"API_KEY": realEstate.keys.tokko, "DEVELOPMENT_ID": id});
    const { data: unities, isLoading: isLoadingUnities } = useGetUnitiesQuery({"API_KEY": realEstate.keys.tokko, "DEVELOPMENT_ID": id});

    return !isLoading ? (
        <section class="ficha-about dev">
            <div class="about-top">
                <div class="container-fluid">
                    <div class="d-lg-flex align-items-lg-start justify-content-lg-between">
                        <div class="column 6 d-lg-none d-block order-1 order-lg-6">
                            <div class="row with-border py-2">
                                <div class="col-12 ps-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-end flex-column">
                                    {(unities?.length === undefined || unities?.length === 0) && !isLoadingUnities ? (
                                        ''
                                    ):
                                        <> 
                                            <div class="item mb-lg-4 d-flex d-lg-block">
                                                <p class="mb-4 mb-lg-0 me-4">Desde</p>
                                                <h4>{isLoadingUnities ? 'Cargando...' : "USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).min)}</h4>
                                            </div>
                                            <div class="item d-flex d-lg-block">
                                                <p class="me-4 mb-lg-0">Hasta</p>
                                                <h4>{isLoadingUnities ? 'Cargando...' : "USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max)}</h4>
                                            </div>                                    
                                        </>
                                     }
                                </div>
                            </div>
                        </div>
                        <div class="column pb-4 pt-5 py-lg-3 d-flex d-lg-block 2 order-lg-1 order-2">
                            {/* <SETTINGS className="mb-3 me-4 me-lg-0" />  */}
                            <i className="icon-settings-light"></i>
                            <div class="info">
                                <h3 class="mb-2 mb-lg-3">ESTADO</h3>
                                <p>{getStatus(development)}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-3 d-flex d-lg-block 1 order-lg-2 order-3">
                            <i className="icon-building-light"></i>
                            <div class="info">
                                <h3 class="mb-2 mb-lg-3">UNIDADES DISPONIBLES</h3>
                                <p>{isLoadingUnities ? 'Cargando...' : getCountUnities(unities)}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-3 d-flex d-lg-block 3 order-lg-3 order-4">
                            <i className="icon-location-light"></i>
                            <div class="info">
                                <h3 class="mb-2 mb-lg-3">UBICACIÓN</h3>
                                <p>{getLocation(development,true)[0]} <br /> {getLocation(development,true)[1]}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-3 d-flex d-lg-block 4 order-lg-4 order-5">
                            {/* <RELOJ className="mb-3 me-4 me-lg-0" />  */}
                            <i className="icon-reloj-light"></i>
                            <div class="info">
                                <h3 class="mb-2 mb-lg-3">ENTREGA</h3>
                                <p>{getContructionDate(development)}</p>
                            </div>                    
                        </div>
                        <div class="column pt-4 pb-5 py-lg-3 d-flex d-lg-block 5 order-lg-5 order-6">
                            {/* <STAR className="mb-3 me-4 me-lg-0" />  */}
                            <i className="icon-star-light"></i>
                            <div class="info">
                                <h3 class="mb-2 mb-lg-3">CALIDAD</h3>
                                <p>Con materiales de la mejor calidad, nuestros proyectos son llevados a cabo para garantizar la durabilidad y asegurar tu inversión.</p>
                            </div>                    
                        </div>
                        <div class="column 6 d-lg-block d-none order-1 order-lg-6">
                            <div class="row with-border py-2">
                                <div class="col-12  ps-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-end flex-column">
                                    {(unities?.length === undefined || unities?.length === 0) && !isLoadingUnities ? (
                                        ''
                                    ):
                                        <> 
                                            <div class="item mb-lg-4 d-flex d-lg-block">
                                                <p class="mb-4 mb-lg-0 mr-4">Desde</p>
                                                <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).min)}</h4>
                                            </div>
                                            <div class="item d-flex d-lg-block">
                                                <p class="mr-4 mb-lg-0">Hasta</p>
                                                <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max)}</h4>
                                            </div>                                    
                                        </>
                                     }
                                </div>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>
            
            <div class="concepto">
                <div class="container-fluid">
                    <div class="row intro">
                        <div className="col-lg-12">
                            <Breadcrumbs
                                props={[
                                { name: realEstate.name, route: "/", location: "" },
                                { name: "Emprendimientos", route:   "/emprendimientos", location: ""},
                                { name: getName(development), route: "", location: ""},
                                ]}
                            />
                        </div>
                        <div class="col-lg-2">
                            <h2 class="section-name">Concepto</h2>
                        </div>
                        <div class="col-lg-10" >
                            <p>{getTitle(development)}</p>
                        </div>
                    </div>     
                </div>
              
            </div>
            <div class="ubicacion-main mt-0">
                <div class="container-fluid">
                    <div class="row intro pt-0 pt-lg-5 pb-5 mt-lg-0">
                        <div class="col-lg-2">
                            <h2 class="section-name">Descripción</h2>
                        </div>
                        <div class="col-lg-10">
                            <p class="description-ficha">
                                {getDescription(development,99999).map((description,index) => (
                                    <>
                                        {description}
                                        <br />
                                    </>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="section-galery">
                <div class="" id="dev-secuencial">
                    <div class="slider" id="dev-gallery">
                    {/* <SimpleReactLightbox>
                        <SRLWrapper options={options}> */}
                            <OwlCarousel
                            items={1}
                            ref={slider}
                            margin={0}
                            nav={false}
                            loop={true}
                            rewind={true}
                            id="prop-gallery">
                                {getPhotos(development).map((photo,index) => (
                                    <div class="item banner-main banner-ficha emprendimiento">
                                            <img src={photo.original} className='object-cover object-center cursor-pointer' alt={realEstate?.name} />
                                </div>
                                ))}
                            </OwlCarousel>
                            {/* <span className="legend-fullscreen">Haz click en la imagen para expandir</span> */}
                        {/* </SRLWrapper>
                    </SimpleReactLightbox> */}

                        <div className="arrows-owl">
                            <div className="arrow reverse" onClick={() => slider.current.prev()}> <i></i> <i></i></div>
                            <div className="arrow " onClick={() => slider.current.next()}> <i></i> <i></i> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div id="video" class={"row global-view " + (getLastVideo(development) === undefined ? 'd-none' : '')}>
                    <div class="col-12">
                        <h4 class="mb-5">{getLastVideo(development)?.title}</h4>
                        {console.log(getLastVideo(development))}
                    </div>
                    <div class="col-12">
                        <div class="vista">
                            <iframe allowfullscreen src={getLastVideo(development) ? getLastVideo(development).player_url : ''} frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tour360" class={"row global-view " + (getTour(development) === undefined ? 'd-none' : '')}>
                    <div class="col-12">
                        <h4 class="mb-5">{getTour(development)?.title}</h4>
                    </div>
                    <div class="col-12">
                        <div class="vista">
                            <iframe allowfullscreen src={getTour(development) ? getTour(development).player_url : ''} frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid files-main">
                <Files files={development?.files} loading={isLoading}/>
            </div>
            <div class="ubicacion-main dev mt-0">
                <div class="container-fluid">
                    <div class="row intro">
                        <div class="col-lg-2">
                            <h2 class="section-name">Ubicación</h2>
                        </div>
                        <div class="col-lg-10">
                            <p>
                            { getFakeAddress(development) + ", " + getLocation(development,true)[0]}<br />
                            {getLocation(development,true)[1]}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <Map coordenadas={{ geo_lat: development.geo_lat, geo_long: development.geo_long }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):
    <>
      <Loading />
    </>
}

export default connect(state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    unities: state.developments.unities,
}),null)(About);